import React, {useEffect, useState, useCallback} from 'react';
import produce from "immer";
import axios from 'axios';
import {message, Spin, Card, Button} from 'antd';
import 'antd/dist/antd.min.css';
import {useConfig} from './ConfigContext';
import EnvInput from './components/EnvInput';
import ModuleRadioGroup from './components/ModuleRadioGroup';

const getQueryString = name => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = window.location.search.substr(1).match(reg);
  let context = '';
  if (r != null) context = r[2];
  return context ?? '';
};

const useComponentModule = (componentName, defaultModule = "") => {
  const [module, setModule] = useState(defaultModule);
  const [config, setConfig] = useConfig();

  useEffect(() => {
    const componentConfigModule = config?.[componentName]?.module;
    // 不要用"??"进行判断，module可能为零值
    setModule(m => componentConfigModule || m);
  }, [config]);

  const onChange = e => {
    setConfig(produce(config, configSnapshot => {
      // 存在初始未存在此key的问题，需初始化配置对象
      if (!(componentName in configSnapshot)) {
        configSnapshot[componentName] = {module: e.target.value, env: {}};
      } else {
        configSnapshot[componentName].module = e.target.value;
      }
    }));
  };

  return [module, onChange];
};

const useComponentEnv = (componentName, envKey, defaultEnvValue) => {
  const [value, setValue] = useState(defaultEnvValue);
  const [config, setConfig] = useConfig();

  useEffect(() => {
    const componentConfigEnvValue = config?.[componentName]?.env?.[envKey];
    // 不要用"??"进行判断，module可能为零值
    setValue(v => componentConfigEnvValue || v);
  }, [config]);

  const updateField = v => {
    setConfig(produce(config, configSnapshot => {
      // 存在初始未存在此key的问题，需初始化配置对象
      if (!(componentName in configSnapshot)) {
        configSnapshot[componentName] = {env: {[envKey]: v}};
      } else {
        configSnapshot[componentName].env[envKey] = v;
      }
      setValue(v);
    }));
  };

  const onChange = e => updateField(e.target.value);

  const onReset = () => updateField(defaultEnvValue);

  return [value, onChange, onReset];
};

const Nav = () => {
  const [module, onModuleChange] = useComponentModule("nav", "bks");

  return (
    <Card title="导航模块">
      <ModuleRadioGroup
        value={module}
        onChange={onModuleChange}
        radios={[
          {value: 'sl', label: '无线导航S'},
          {value: 'bks', label: '无线导航B'},
          {value: 'jjmgt', label: '磁导航'},
          {value: 'jjbase', label: '简易底盘'}
        ]}
      />
    </Card>
  );
};

const Network = () => {
  const [module, onModuleChange] = useComponentModule("network", "wifi360");
  const [deviceHost, onDeviceHostChange, onDeviceHostReset] = useComponentEnv("network", "device", "10.10.10.1:80");
  const [password, onPasswordChange, onPasswordReset] = useComponentEnv("network", "p", "andy4wifi");

  return (
    <Card title="网络模块">
      <ModuleRadioGroup
        title="路由类型"
        value={module}
        onChange={onModuleChange}
        radios={[
          {value: 'wifi360', label: '360路由'},
          {value: 'openwrt', label: 'OpenWrt'}
        ]}
      />
      <EnvInput
        title="路由登录地址"
        value={deviceHost}
        onChange={onDeviceHostChange}
        onResetClick={onDeviceHostReset}
      />
      <EnvInput
        title="路由登录密码"
        value={password}
        onChange={onPasswordChange}
        onResetClick={onPasswordReset}
      />
    </Card>
  );
};

const Auth = () => {
  const [password, onPasswordChange, onPasswordReset] = useComponentEnv("auth", "PASSWORD", "123456");
  const [timeout, onTimeoutChange, onTimeoutReset] = useComponentEnv("auth", "jwt_timeout_sec", "604800");

  return (
    <Card title="权限认证模块">
      <EnvInput
        title="登录密码"
        value={password}
        onChange={onPasswordChange}
        onResetClick={onPasswordReset}
      />
      <EnvInput
        title="权限过期时间（秒）"
        type="number"
        min="0"
        value={timeout}
        onChange={onTimeoutChange}
        onResetClick={onTimeoutReset}
      />
    </Card>
  );
};

const Speech = () => {
  const [ssid, onSsidChange, onSsidReset] = useComponentEnv("speech", "wifi_ssid", "");
  const [pwd, onPwdChange, onPwdReset] = useComponentEnv("speech", "wifi_pwd", "");

  return (
    <Card title="语音模块">
      <EnvInput
        title="无线网络SSID"
        value={ssid}
        onChange={onSsidChange}
        onResetClick={onSsidReset}
        description="留空时将自动获取当前路由设备SSID"
      />
      <EnvInput
        title="无线网络密码"
        value={pwd}
        onChange={onPwdChange}
        onResetClick={onPwdReset}
        description="留空时将自动获取当前路由设备PWD"
      />
    </Card>
  );
};

const Expression = () => {
  const [module, onModuleChange] = useComponentModule("expression", "v1");

  return (
    <Card title="表情模块">
      <ModuleRadioGroup
        value={module}
        onChange={onModuleChange}
        radios={[
          {value: 'v1', label: 'v1'},
          {value: 'v2', label: 'v2 Live'},
        ]}
      />
    </Card>
  );
};

const Lights = () => {
  const [module, onModuleChange] = useComponentModule("lights", "v1");

  return (
    <Card title="灯光模块">
      <ModuleRadioGroup
        value={module}
        onChange={onModuleChange}
        radios={[
          {value: 'v1', label: 'v1'},
          {value: 'v2', label: 'v2 Live'},
        ]}
      />
      {module === "v1" && <LightsV1/>}
      {module === "v2" && <LightsV2/>}
    </Card>
  );
};

const LightsV1 = () => {
  const [hands, onHandsChange, onHandsReset] = useComponentEnv("lights", "hands_length", "62");
  const [ears, onEarsChange, onEarsReset] = useComponentEnv("lights", "ears_length", "0");
  const [mouth, onMouthChange, onMouthReset] = useComponentEnv("lights", "mouth_length", "0");

  return (
    <>
      <EnvInput
        title="手臂灯光数"
        type="number"
        min="0"
        value={hands}
        onChange={onHandsChange}
        onResetClick={onHandsReset}
      />
      <EnvInput
        title="嘴部灯光数"
        type="number"
        min="0"
        value={mouth}
        onChange={onMouthChange}
        onResetClick={onMouthReset}
      />
      <EnvInput
        title="耳部灯光数"
        type="number"
        min="0"
        value={ears}
        onChange={onEarsChange}
        onResetClick={onEarsReset}
      />
    </>
  );
};

const LightsV2 = () => {
  const [hands, onHandsChange, onHandsReset] = useComponentEnv("lights", "HANDS_RANGE", "0,62");
  const [ears, onEarsChange, onEarsReset] = useComponentEnv("lights", "EARS_RANGE", "0,0");
  const [mouth, onMouthChange, onMouthReset] = useComponentEnv("lights", "MOUTH_RANGE", "0,0");

  return (
    <>
      <EnvInput
        title="手臂灯光数"
        value={hands}
        onChange={onHandsChange}
        onResetClick={onHandsReset}
        description="手臂灯光范围,格式:[起始,长度],范围区间不可重叠"
      />
      <EnvInput
        title="嘴部灯光数"
        value={mouth}
        onChange={onMouthChange}
        onResetClick={onMouthReset}
        description="嘴部灯光范围,格式:[起始,长度],范围区间不可重叠"
      />
      <EnvInput
        title="耳部灯光数"
        value={ears}
        onChange={onEarsChange}
        onResetClick={onEarsReset}
        description="耳部灯光范围,格式:[起始,长度],范围区间不可重叠"
      />
    </>
  );
};

const HeadMotion = () => {
  const [xLeft, onXLeftChange, onXLeftReset] = useComponentEnv("motion", "HEAD_X_AXIS_LEFT", "75");
  const [xCenter, onXCenterChange, onXCenterReset] = useComponentEnv("motion", "HEAD_X_AXIS_CENTER", "135");
  const [xRight, onXRightChange, onXRightReset] = useComponentEnv("motion", "HEAD_X_AXIS_RIGHT", "195");
  const [xOffset, onXOffsetChange, onXOffsetReset] = useComponentEnv("motion", "HEAD_X_AXIS_OFFSET", "0");

  const [yTop, onYTopChange, onYTopReset] = useComponentEnv("motion", "HEAD_Y_AXIS_TOP", "120");
  const [yMiddle, onYMiddleChange, onYMiddleReset] = useComponentEnv("motion", "HEAD_Y_AXIS_MIDDLE", "135");
  const [yBottom, onYBottomChange, onYBottomReset] = useComponentEnv("motion", "HEAD_Y_AXIS_BOTTOM", "155");
  const [yOffset, onYOffsetChange, onYOffsetReset] = useComponentEnv("motion", "HEAD_Y_AXIS_OFFSET", "0");

  return (
    <Card title="头部运动模块">
      <EnvInput
        title="头部水平左极限"
        type="number"
        value={xLeft}
        onChange={onXLeftChange}
        onResetClick={onXLeftReset}
        description="头部水平方向上左转极限值"
      />
      <EnvInput
        title="头部水平右极限"
        type="number"
        value={xRight}
        onChange={onXRightChange}
        onResetClick={onXRightReset}
        description="头部水平方向上右转极限值"
      />
      <EnvInput
        title="头部水平居中"
        type="number"
        value={xCenter}
        onChange={onXCenterChange}
        onResetClick={onXCenterReset}
        description="头部水平方向上居中值"
      />
      <EnvInput
        title="头部水平修正"
        type="number"
        value={xOffset}
        onChange={onXOffsetChange}
        onResetClick={onXOffsetReset}
        description="头部水平方向上的偏移修正, e.g. -5: 左偏移5度, 8: 右偏移8度"
      />
      <EnvInput
        title="头部垂直顶极限"
        type="number"
        value={yTop}
        onChange={onYTopChange}
        onResetClick={onYTopReset}
        description="头部垂直方向抬头极限值"
      />
      <EnvInput
        title="头部垂直底极限"
        type="number"
        value={yBottom}
        onChange={onYBottomChange}
        onResetClick={onYBottomReset}
        description="头部垂直方向低头极限值"
      />
      <EnvInput
        title="头部垂直居中"
        type="number"
        value={yMiddle}
        onChange={onYMiddleChange}
        onResetClick={onYMiddleReset}
        description="头部垂直方向居中值"
      />
      <EnvInput
        title="头部垂直修正"
        type="number"
        value={yOffset}
        onChange={onYOffsetChange}
        onResetClick={onYOffsetReset}
        description="头部垂直方向上的偏移修正值, e.g. -5: 下移5度, 8: 上移8度"
      />
    </Card>
  );
};

function App() {
  const sn = getQueryString('sn');
  const isIframe = !!getQueryString('no-iframe');
  const [initialized, setInitialized] = useState(false);
  const [config, setConfig] = useConfig();

  // 初始加载配置数据
  useEffect(() => {
    axios
      .get('/api/v1/config', {params: {sn}})
      .then(res => {
        setConfig(res.data.services);
        setInitialized(true);
      })
      .catch(err => {
        window.console.error(err);
      });
  }, [sn]);

  // 上传配置
  const updateConfig = useCallback(() => {
    axios
      .post('/api/v1/config', {
        sn,
        config: {
          services: config,
          exclude: []
        }
      })
      .then(() => {
        window.parent.postMessage({type: 'configUpdated'}, '*');
        message.success('配置已保存');
      })
      .catch(() => {
        message.error('配置保存失败');
      });
  }, [sn, config]);

  // 监听iframe上层页面消息
  useEffect(() => {
    const listener = e => {
      if (e.data.type === 'configShouldUpdate') {
        updateConfig();
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [updateConfig]);

  return (
    <Spin
      style={{height: '100vh'}}
      tip={'Loading...'}
      spinning={!initialized}
    >
      <div className="App">
        {isIframe && <Button onClick={updateConfig}>配置好了</Button>}
        <Nav/>
        <Network/>
        <Auth/>
        <Speech/>
        <Expression/>
        <Lights/>
        <HeadMotion/>
      </div>
    </Spin>
  );
}

export default App;
