import React from 'react';
import { Radio } from 'antd';

const ModuleRadioGroup = ({ title, radios = [], value, onChange }) => {
  return (
    <div>
      <h4>{title}</h4>
      <Radio.Group value={value} onChange={onChange}>
        {radios?.map(r => (
          <Radio key={r.value} value={r.value}>
            {r.label}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

export default ModuleRadioGroup;
