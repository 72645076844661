import React, {useState, useContext} from "react";

const ConfigContext = React.createContext(null);

export const ConfigProvider = ({children}) => {
  const [config, setConfig] = useState(null);
  return <ConfigContext.Provider value={[config, setConfig]}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => useContext(ConfigContext);
