import React from 'react';
import { Input } from 'antd';
import styles from './EnvInput.module.scss';

const EnvInput = ({
  title,
  placeholder,
  type = 'text',
  min,
  max,
  disabled,
  name,
  value,
  onChange,
  onResetClick,
  description
}) => {
  return (
    <div>
      <h4>{title}</h4>
      <Input.Search
        placeholder={placeholder}
        className={styles.Input}
        type={type}
        min={min}
        max={max}
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
        onSearch={onResetClick}
        enterButton="恢复默认"
      />
      {description && (
        <div className={styles.Description}>
          <span className={styles.Mark}>*</span>
          {description}
        </div>
      )}
    </div>
  );
};

export default EnvInput;
